import React from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { chatbubbleEllipsesOutline } from 'ionicons/icons';
import moment from 'moment-timezone';

const CommentSection = ({
  goalId,
  comments = [], // Default to an empty array to avoid undefined errors
  commentText = '', // Default to an empty string to avoid undefined errors
  setCommentText = () => {}, // Default to a no-op function
  handleAddComment = () => {}, // Default to a no-op function
}) => {
  const formatDate = (dateString) => {
    return moment(dateString).fromNow();
  };

  return (
    <div>
      {/* Comments List */}
      <IonList>
        {comments.length > 0 ? (
          comments.map((comment) => (
            <IonItem key={comment._id} lines="none">
              <IonLabel>
                <IonGrid>
                  <IonRow>
                    {/* Commenter Name */}
                    <IonCol size="8">
                      <IonText color="primary">
                        <strong>
                          {comment.user
                            ? `${comment.user.firstName} ${comment.user.lastName}`
                            : 'Anonymous'}
                        </strong>
                      </IonText>
                    </IonCol>
                    {/* Timestamp */}
                    <IonCol size="4" className="ion-text-right">
                      <IonText color="medium">
                        <p className="comment-date">{formatDate(comment.createdAt)}</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    {/* Comment Text */}
                    <IonCol>
                      <IonText color="dark">
                        <p>{comment.text}</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonLabel>
            </IonItem>
          ))
        ) : (
          <IonItem lines="none">
            <IonLabel>
              <IonText color="medium">No comments yet.</IonText>
            </IonLabel>
          </IonItem>
        )}
      </IonList>

      {/* Add Comment Form */}
      <div className="comment-form">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonTextarea
                value={commentText} // Matches prop passed from GoalCard
                onIonChange={(e) => {
                  console.log('Textarea Change:', e.detail.value); // Debug log
                  setCommentText(e.detail.value);
                }}
                placeholder="Enter your comment"
                autoGrow
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                color="primary"
                onClick={() => {
                  console.log('Adding comment:', { goalId, commentText }); // Debug log
                  handleAddComment(goalId, commentText); // Pass both correctly
                }}
                disabled={!commentText.trim()}
                aria-label="Add Comment"
                title="Add Comment"
              >
                Add Comment
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default CommentSection;
