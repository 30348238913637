import React from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { cashOutline } from 'ionicons/icons';

const RewardSection = ({
  goalId,
  rewards,
  rewardAmount,
  setRewardAmount,
  handleAddReward,
}) => {
  const handleAddRewardClick = () => {
    if (!rewardAmount || parseFloat(rewardAmount) <= 0) {
      alert('Please enter a valid reward amount.');
      return;
    }
    handleAddReward(goalId); // Call the reward handler with the goal ID
  };

  return (
    <div>
      {/* Display the list of rewards */}
      <IonList>
        {rewards && rewards.length > 0 ? (
          rewards.map((reward) => (
            <IonItem key={reward._id}>
              <IonLabel>
                <IonText color="primary">
                  <strong>
                    {reward.user
                      ? `${reward.user.firstName} ${reward.user.lastName}`
                      : 'Anonymous'}
                  </strong>
                </IonText>
              </IonLabel>
              <IonLabel slot="end">
                <IonText color="secondary">
                  <strong>${parseFloat(reward.amount).toFixed(2)}</strong>
                </IonText>
              </IonLabel>
            </IonItem>
          ))
        ) : (
          <IonItem>
            <IonLabel>
              <IonText color="medium">No rewards yet.</IonText>
            </IonLabel>
          </IonItem>
        )}
      </IonList>

      {/* Add Reward Form */}
      <div className="reward-form">
        <IonGrid>
          <IonRow>
            <IonCol size="9">
              <IonItem>
                <IonInput
                  value={rewardAmount}
                  onIonChange={(e) => {
                    if (setRewardAmount) {
                      setRewardAmount(e.detail.value);
                    } else {
                      console.error('setRewardAmount is not defined.');
                    }
                  }}
                  placeholder="Enter reward amount"
                  type="number"
                  min="0"
                />
              </IonItem>
            </IonCol>
            <IonCol size="3">
              <IonButton expand="block" color="primary" onClick={handleAddRewardClick}>
                <IonIcon slot="start" icon={cashOutline} />
                Add
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default RewardSection;
