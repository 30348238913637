import React, { useState, useEffect } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonText,
  IonBadge,
} from '@ionic/react';
import { chatbubbleOutline, addCircleOutline, heartOutline, heartSharp } from 'ionicons/icons';
import CommentSection from './CommentSection';
import RewardSection from './RewardSection';
import moment from 'moment-timezone';
import './GoalCard.css';

const GoalCard = ({
  goal,
  onLike,
  onUnlike,
  onAddComment,
  onAddReward,
  onStatusChange,
  isTeamLeader,
  commentText,
  setCommentText,
  rewardAmount,
  setRewardAmount,
}) => {
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [activeRewardId, setActiveRewardId] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(goal.likes?.length || 0);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (goal && Array.isArray(goal.likes)) {
      const userLiked = goal.likes.some((likeEntry) =>
        // If likeEntry is an object with an _id, compare _id.
        // If it's a string (just the userId), compare directly.
        (likeEntry && likeEntry._id)
          ? String(likeEntry._id) === String(userId)
          : String(likeEntry) === String(userId)
      );
      setIsLiked(userLiked);
      setLikesCount(goal.likes.length);
    }
  }, [goal]);
  

  const formatTimeRemaining = (dateString) => {
    const now = moment();
    const targetDate = moment.tz(dateString, 'America/Los_Angeles').endOf('day');
    const duration = moment.duration(targetDate.diff(now));
    const days = duration.asDays();
    const hours = duration.asHours();

    if (days < -1) {
      return `${Math.abs(Math.ceil(days))} days ago`;
    } else if (days < 0) {
      return `${Math.abs(Math.ceil(hours))} hours ago`;
    } else if (days >= 1) {
      return `in ${Math.floor(days)} days`;
    } else {
      return `in ${Math.floor(hours)} hours`;
    }
  };

  const handleToggleComments = () => {
    setActiveCommentId(activeCommentId === goal._id ? null : goal._id);
    if (activeRewardId === goal._id) setActiveRewardId(null);
  };

  const handleToggleRewards = () => {
    setActiveRewardId(activeRewardId === goal._id ? null : goal._id);
    if (activeCommentId === goal._id) setActiveCommentId(null);
  };

  const handleLikeToggle = async () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.error('Not logged in.');
      return;
    }

    try {
      let updatedGoal;

      if (isLiked) {
        setLikesCount((prev) => Math.max(prev - 1, 0));
        setIsLiked(false);
        updatedGoal = await onUnlike(goal._id);
      } else {
        setLikesCount((prev) => prev + 1);
        setIsLiked(true);
        updatedGoal = await onLike(goal._id);
      }

      goal.likes = updatedGoal.likes;
      setLikesCount(updatedGoal.likes.length);
      setIsLiked(updatedGoal.likes.some((like) => String(like._id) === String(userId)));
    } catch (error) {
      console.error('Error in handleLikeToggle:', error);

      setLikesCount(goal.likes.length);
      setIsLiked(goal.likes.some((user) => String(user) === String(localStorage.getItem('userId'))));
    }
  };

  return (
    <IonCard color="light">
      <IonCardHeader>
        <IonGrid fixed>
          <IonRow>
            <IonCol size="8" className="ion-text-left">
              {goal.user ? (
                <IonText color="primary">
                  <strong>{`${goal.user.firstName} ${goal.user.lastName}`}</strong>
                </IonText>
              ) : (
                <IonText color="medium">
                  <strong>Unknown User</strong>
                </IonText>
              )}
            </IonCol>
            <IonCol size="4" className="ion-text-right">
              <IonBadge color="secondary">
                {goal.targetCompletionDate
                  ? formatTimeRemaining(goal.targetCompletionDate)
                  : 'No Deadline'}
              </IonBadge>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardHeader>

      <IonCardContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol size="12" className="ion-text-left">
              <IonCardTitle>{goal.title}</IonCardTitle>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="12" className="ion-text-left">
              <IonSelect
                interface="popover"
                placeholder="Select Status"
                value={goal.status}
                onIonChange={(e) => onStatusChange(e.detail.value)}
              >
                {['To Do', 'Doing', 'Done', 'Archived', 'Pending Approval', 'Complete'].map(
                  (status) => (
                    <IonSelectOption
                      key={status}
                      value={status}
                      disabled={status === 'Complete' && !isTeamLeader}
                    >
                      {status}
                    </IonSelectOption>
                  )
                )}
              </IonSelect>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="12" className="ion-text-left">
              <IonText color="dark">
                <p className="goal-description">{goal.description || 'No description available'}</p>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid fixed className="interaction-section">
          <IonRow>
            <IonCol size="4" className="ion-text-center">
              <IonButton fill="clear" onClick={handleLikeToggle}>
                <IonIcon icon={isLiked ? heartSharp : heartOutline} color={isLiked ? 'danger' : 'medium'} />
                <IonText color={isLiked ? 'danger' : 'medium'}> {likesCount}</IonText>
              </IonButton>
            </IonCol>
            <IonCol size="4" className="ion-text-center">
              <IonButton fill="clear" onClick={handleToggleComments}>
                <IonIcon icon={chatbubbleOutline} />
                <IonText color="medium"> {goal.comments.length}</IonText>
              </IonButton>
            </IonCol>
            <IonCol size="4" className="ion-text-center">
              <IonButton fill="clear" onClick={handleToggleRewards}>
                <IonIcon icon={addCircleOutline} />
                <IonText color="secondary">
                  ${goal.rewards.reduce((sum, r) => sum + r.amount, 0).toFixed(2)}
                </IonText>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>

      {activeCommentId === goal._id && (
        <IonCardContent>
          <CommentSection
            goalId={goal._id}
            comments={goal.comments}
            commentText={commentText}
            setCommentText={setCommentText}
            handleAddComment={onAddComment}
          />
        </IonCardContent>
      )}

      {activeRewardId === goal._id && (
        <IonCardContent>
          <RewardSection
            goalId={goal._id}
            rewards={goal.rewards}
            rewardAmount={rewardAmount}
            setRewardAmount={setRewardAmount}
            handleAddReward={onAddReward}
          />
        </IonCardContent>
      )}
    </IonCard>
  );
};

export default GoalCard;
