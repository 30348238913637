// src/pages/GoalDetailPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonButton,
  IonIcon,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AlertSidebar from './AlertSidebar';
import GoalCard from '../components/GoalCard';
import {
  fetchGoalDetails,
  addComment,
  addReward,
  changeStatus,
  likeGoal,
  unlikeGoal,
} from '../api/apiGoal';
import { fetchUserTeams } from '../api/apiTeam';

const GoalDetailPage = () => {
  const { goalId } = useParams();
  const [goal, setGoal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [teams, setTeams] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [rewardAmount, setRewardAmount] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastColor, setToastColor] = useState('success');
  const [isLiked, setIsLiked] = useState(false);
  const history = useHistory();
  const orgId = localStorage.getItem('activeOrganization');
  const userId = localStorage.getItem('userId'); // Adjust based on your auth implementation

  /**
   * Handles toggling the like/unlike state of the goal.
   */
  const handleLikeToggle = async () => {
    console.log(`Attempting to ${isLiked ? 'unlike' : 'like'} goal with _id:`, goal._id);
    try {
      // Optimistically toggle the like state
      setIsLiked((prev) => !prev);

      const updatedGoal = isLiked
        ? await unlikeGoal(goal._id)
        : await likeGoal(goal._id);

      // Update the goal state and synchronize the like state
      setGoal(updatedGoal);
      setIsLiked(updatedGoal.likes.some((user) => String(user._id) === String(userId)));
    } catch (error) {
      console.error('Error in handleLikeToggle:', error);

      // Rollback the like state in case of error
      setIsLiked((prev) => !prev);
      setToastMessage('Error toggling like.');
      setToastColor('danger');
      setShowToast(true);
    }
  };

  /**
   * Handles adding a comment to the goal.
   */
  const handleAddComment = async () => {
    try {
      const newComment = await addComment(goal._id, commentText);
      setGoal((prevGoal) => ({
        ...prevGoal,
        comments: [...prevGoal.comments, newComment],
      }));
      setCommentText('');
      setToastMessage('Comment added successfully!');
      setToastColor('success');
      setShowToast(true);
    } catch (error) {
      console.error('Error adding comment:', error);
      setToastMessage(error.message || 'Failed to add comment.');
      setToastColor('danger');
      setShowToast(true);
    }
  };

  /**
   * Handles adding a reward to the goal.
   */
  const handleAddReward = async () => {
    try {
      const newReward = await addReward(goal._id, rewardAmount);
      setGoal((prevGoal) => ({
        ...prevGoal,
        rewards: [...prevGoal.rewards, newReward],
      }));
      setRewardAmount('');
      setToastMessage('Reward added successfully!');
      setToastColor('success');
      setShowToast(true);
    } catch (error) {
      console.error('Error adding reward:', error);
      setToastMessage(error.message || 'Failed to add reward.');
      setToastColor('danger');
      setShowToast(true);
    }
  };

  /**
   * Handles changing the status of the goal.
   */
  const handleStatusChange = async (newStatus) => {
    try {
      await changeStatus(goal._id, newStatus);
      setGoal((prevGoal) => ({
        ...prevGoal,
        status: newStatus === 'Done' ? 'Pending Approval' : newStatus,
      }));
      setToastMessage('Status updated successfully!');
      setToastColor('success');
      setShowToast(true);
    } catch (error) {
      console.error('Error updating status:', error);
      setToastMessage(error.message || 'Failed to update status.');
      setToastColor('danger');
      setShowToast(true);
    }
  };

  useEffect(() => {
    const fetchGoalData = async () => {
      try {
        const data = await fetchGoalDetails(goalId);
        if (!data.likes) data.likes = [];
        if (!data.user) data.user = {};
        setGoal(data);

        // Determine if the current user has liked the goal
        const userLiked = data.likes.some((user) => String(user._id) === String(userId));
        setIsLiked(userLiked);
      } catch (error) {
        console.error('Error fetching goal details:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchTeamsData = async () => {
      try {
        const teamsData = await fetchUserTeams(userId, orgId);
        setTeams(teamsData);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    fetchGoalData();
    fetchTeamsData();
  }, [goalId, userId, orgId]);

  if (loading) {
    return (
      <IonPage>
        <AppHeader />
        <IonContent className="ion-padding ion-text-center">
          <IonSpinner name="crescent" />
          <p>Loading goal details...</p>
        </IonContent>
        <AppFooter />
      </IonPage>
    );
  }

  if (error) {
    return (
      <IonPage>
        <AppHeader />
        <IonContent className="ion-padding ion-text-center">
          <IonText color="danger">
            <h2>Error loading goal: {error}</h2>
          </IonText>
        </IonContent>
        <AppFooter />
      </IonPage>
    );
  }

  if (!goal) {
    return (
      <IonPage>
        <AppHeader />
        <IonContent className="ion-padding ion-text-center">
          <IonText color="medium">
            <h2>Goal not found.</h2>
          </IonText>
        </IonContent>
        <AppFooter />
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          style={{
            '--background': 'linear-gradient(0deg, #006d77, #479d9d)',
          }}
        >
          <IonButtons slot="start">
            <IonButton onClick={() => history.push(`/feed/${orgId}`)} color="light">
              <IonIcon icon={arrowBackOutline} slot="start" />
              Back to Feed
            </IonButton>
          </IonButtons>
          <IonTitle align="right" color="light">
            Goal Details
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <GoalCard
          goal={goal}
          onLike={likeGoal}
          onUnlike={unlikeGoal}
          onAddComment={handleAddComment}
          onAddReward={handleAddReward}
          onStatusChange={handleStatusChange}
          isTeamLeader={goal.associatedTeam?.leader?.some((leader) =>
            String(leader.memberId) === String(userId)
          )}
          commentText={commentText}
          setCommentText={setCommentText}
          rewardAmount={rewardAmount}
          setRewardAmount={setRewardAmount}
        />
      </IonContent>
      <AppFooter />
      <AlertSidebar visible={sidebarVisible} onHide={() => setSidebarVisible(false)} />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        position="bottom"
        color={toastColor}
      />
    </IonPage>
  );
};

export default GoalDetailPage;
