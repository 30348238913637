// App.js
import React, { useEffect, useState } from 'react';
import { IonApp } from '@ionic/react';  // Import IonApp
import { IonReactRouter } from '@ionic/react-router';  // Import IonReactRouter
import { Route, Redirect, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { createMedia } from '@artsy/fresnel';
import HomePage from './components/HomePage';
import ProfilePage from './components/ProfilePage';
import ProfileDetailPage from './components/ProfileDetailPage';
import FeedPage from './components/FeedPage';
import Login from './components/Login';
import Signup from './components/Signup';
import CreateGoal from './components/CreateGoal';
import Kanban from './components/Kanban';
import TeamPage from './components/TeamPage';
import InviteUsers from './components/InviteUsers';
import CreateTeams from './components/CreateTeams';
import TeamsList from './components/TeamsList'; // Ensure this line is present
import Success from './components/Success';
import Register from './components/Register';
import GoalDetailPage from './components/GoalDetailPage';
import { AlertProvider } from './context/AlertContext'; // Import the AlertProvider
import StripeTest from './components/StripeTest'; // Import the StripeTest component
import API_BASE_URL from './config'; // Import the config
import WaitlistPage from './components/WaitlistPage'; // Import the WaitlistPage component
import SecretAccess from './components/SecretAccess'; // Import the SecretAccess component

const { MediaContextProvider } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

// Existing PrivateRoute for authentication based on token
function PrivateRoute({ component: Component, render, ...rest }) {
  const isAuthenticated = !!localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          Component ? <Component {...props} {...rest} /> : render(props)
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location, redirected: true } }} />
        )
      }
    />
  );
}

// New ProtectedRoute for secret access based on 'hasSecretAccess' flag
function ProtectedRoute({ component: Component, ...rest }) {
  const hasSecretAccess = localStorage.getItem('hasSecretAccess') === 'true';
  return (
    <Route
      {...rest}
      render={props =>
        hasSecretAccess ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
}

function RedirectToOrgName() {
  const { orgId } = useParams();
  const history = useHistory();  // Use useHistory instead of useNavigate
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrgNameAndRedirect = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve the stored JWT token
        if (!token) {
          throw new Error('No authentication token found.');
        }

        const response = await fetch(`${API_BASE_URL}/api/organization/id/${orgId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Organization not found');
        }

        const orgData = await response.json();
        const orgNameFormatted = orgData.name.replace(/ /g, '-');
        history.push(`/${orgNameFormatted}/feed`);  // Use history.push instead of navigate
      } catch (error) {
        console.error('Redirection Error:', error);
        // Redirect to an error page or handle the error accordingly
      } finally {
        setLoading(false);
      }
    };

    if (orgId) {
      fetchOrgNameAndRedirect();
    }
  }, [orgId, history]);

  if (loading) {
    return <p>Redirecting...</p>;
  }

  // Optionally, handle loading state differently or return null
  return null;
}

export default function App() {
  const [teamCreated, setTeamCreated] = useState(false);

  const handleTeamCreated = () => {
    setTeamCreated(prev => !prev); // Toggle the state to trigger re-render
  };

  return (
    <IonApp>
      <IonReactRouter>
        <MediaContextProvider>
          <AlertProvider>
            <Switch>
              {/* 1. Secret Access Route */}
              <Route path="/secret-access" component={SecretAccess} exact />

              {/* 2. Default Route: Waitlist Page */}
              <Route exact path="/" component={WaitlistPage} />

              {/* 3. Home Page Route (Protected via ProtectedRoute) */}
              <ProtectedRoute path="/home" component={HomePage} exact />

              {/* 4. Other Routes */}
              <Route path="/register" component={Register} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />

              <PrivateRoute path="/invite-users" component={InviteUsers} />
              <PrivateRoute
                path="/create-teams"
                render={props => <CreateTeams {...props} onTeamCreated={handleTeamCreated} />}
              />
              <PrivateRoute
                path="/teams-list"
                render={props => <TeamsList {...props} onTeamCreated={teamCreated} />}
              />
              <PrivateRoute path="/profile" component={ProfilePage} />
              <PrivateRoute path="/profile/:username" component={ProfileDetailPage} />
              <Route path="/feed/:orgId" component={RedirectToOrgName} />
              <PrivateRoute path="/:organization/feed" component={FeedPage} />
              <PrivateRoute path="/create-goal" component={CreateGoal} />
              <PrivateRoute path="/success" component={Success} />
              <PrivateRoute path="/kanban" component={Kanban} />
              <PrivateRoute path="/team/:teamId" component={TeamPage} />
              <Route path="/goal/:goalId" component={GoalDetailPage} />
              <PrivateRoute path="/stripe-test" component={StripeTest} />

              {/* 5. Redirect any unknown routes to Waitlist Page */}
              <Redirect to="/" />
            </Switch>
          </AlertProvider>
        </MediaContextProvider>
      </IonReactRouter>
    </IonApp>
  );
}
