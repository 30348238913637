import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonIcon,
  IonButton,
  IonSpinner,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonModal,
  IonList,
  IonItem,
} from '@ionic/react';
import { people, add, close } from 'ionicons/icons';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import { fetchTeamById } from '../api/apiTeam';
import {
  fetchGoalsByTeamId,
  fetchGoalDetails,
  changeStatus,
  likeGoal,
  unlikeGoal,
  addReward,
  addComment
} from '../api/apiGoal';
import GoalCard from '../components/GoalCard';
import '../index.css';

const TeamPage = () => {
  const { teamId } = useParams();
  const history = useHistory();

  const [teamName, setTeamName] = useState('');
  const [teamGoals, setTeamGoals] = useState([]);
  const [individualGoals, setIndividualGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTimeframe, setSelectedTimeframe] = useState('Day');
  const [memberCount, setMemberCount] = useState(0);
  const [teamMembers, setTeamMembers] = useState([]);
  const [showMembersModal, setShowMembersModal] = useState(false);

  const [rewardAmounts, setRewardAmounts] = useState({});
  const [commentTexts, setCommentTexts] = useState({});

  const fetchTeamData = async () => {
    try {
      const teamResponse = await fetchTeamById(teamId);
      console.log('Team Response:', teamResponse); // Check the structure here

      setTeamName(teamResponse.name);
      setMemberCount(teamResponse.members.length);
      setTeamMembers(teamResponse.members);

      const goalsResponse = await fetchGoalsByTeamId(teamId);

      // Ensure rewards is always an array
      const normalizedGoals = goalsResponse.map((goal) => ({
        ...goal,
        rewards: goal.rewards || [],
      }));

      setTeamGoals(normalizedGoals.filter((goal) => goal.type === 'Team'));
      setIndividualGoals(normalizedGoals.filter((goal) => goal.type === 'Individual'));
    } catch (err) {
      setError('Error fetching team data.');
      console.error('Error fetching team data:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (teamId) {
      fetchTeamData();
    }
  }, [teamId]);

  const handleStatusChange = async (goalId, newStatus) => {
    try {
      const updatedGoal = await changeStatus(goalId, newStatus);
      setTeamGoals((prevGoals) =>
        prevGoals.map((goal) => (goal._id === goalId ? updatedGoal : goal))
      );
      setIndividualGoals((prevGoals) =>
        prevGoals.map((goal) => (goal._id === goalId ? updatedGoal : goal))
      );
    } catch (err) {
      setError('Failed to update goal status.');
      console.error('Error updating status:', err);
    }
  };

  const handleLikeToggle = async (goalId) => {
    try {
      const userId = localStorage.getItem('userId');
      const goal =
        teamGoals.find((g) => g._id === goalId) ||
        individualGoals.find((g) => g._id === goalId);

      if (!goal) return;

      // Determine if user already liked the goal
      const isLiked = goal.likes.some((likeUser) =>
        likeUser && likeUser._id
          ? String(likeUser._id) === String(userId)
          : String(likeUser) === String(userId)
      );

      let updatedGoal;
      if (isLiked) {
        // User already liked, so we unlike
        updatedGoal = await unlikeGoal(goalId);
      } else {
        // User hasn't liked yet, so we like
        updatedGoal = await likeGoal(goalId);
      }

      // Update state with the updated goal
      setTeamGoals((prevGoals) =>
        prevGoals.map((g) => (g._id === goalId ? updatedGoal : g))
      );
      setIndividualGoals((prevGoals) =>
        prevGoals.map((g) => (g._id === goalId ? updatedGoal : g))
      );
    } catch (err) {
      console.error('Error toggling like/unlike:', err);
      setError('Failed to toggle like/unlike.');
    }
  };

  const handleAddComment = async (goalId, commentText) => {
    try {
      if (!commentText.trim()) {
        throw new Error('Comment cannot be empty.');
      }

      await addComment(goalId, commentText);
      const updatedGoal = await fetchGoalDetails(goalId);

      setTeamGoals((prevGoals) =>
        prevGoals.map((goal) => (goal._id === goalId ? updatedGoal : goal))
      );
      setIndividualGoals((prevGoals) =>
        prevGoals.map((goal) => (goal._id === goalId ? updatedGoal : goal))
      );

      setCommentTexts((prevComments) => ({
        ...prevComments,
        [goalId]: '',
      }));
    } catch (err) {
      console.error('Error adding comment:', err);
      setError('Failed to add comment.');
    }
  };

  const handleAddReward = async (goalId) => {
    try {
      const reward = parseFloat(rewardAmounts[goalId] || 0);
      if (isNaN(reward) || reward <= 0) {
        throw new Error('Please enter a valid reward amount.');
      }

      await addReward(goalId, reward);
      const updatedGoal = await fetchGoalDetails(goalId);

      setTeamGoals((prevGoals) =>
        prevGoals.map((goal) => (goal._id === goalId ? updatedGoal : goal))
      );
      setIndividualGoals((prevGoals) =>
        prevGoals.map((goal) => (goal._id === goalId ? updatedGoal : goal))
      );

      setRewardAmounts((prevAmounts) => ({
        ...prevAmounts,
        [goalId]: '',
      }));
    } catch (err) {
      console.error('Error adding reward:', err);
      setError('Failed to add reward.');
    }
  };

  const handleCreateGoalClick = () => {
    history.push('/create-goal');
  };

  if (loading) {
    return (
      <IonPage>
        <AppHeader />
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol size="auto" className="ion-text-center">
                <IonSpinner name="crescent" />
                <p>Loading team data...</p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <AppFooter />
      </IonPage>
    );
  }

  if (error) {
    return (
      <IonPage>
        <AppHeader />
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol size="auto">
                <p style={{ color: 'red' }}>{error}</p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <AppFooter />
      </IonPage>
    );
  }

  return (
    <IonPage id="main-content">
      <AppHeader />
      <IonContent className="ion-padding">
        <IonGrid>
          {/* Team Header */}
          <IonRow className="ion-margin-bottom ion-align-items-center">
            <IonCol size="12" sizeMd="8">
              <IonTitle style={{ fontSize: '1.75rem', fontWeight: 'bold' }}>{teamName}</IonTitle>
            </IonCol>
            <IonCol size="12" sizeMd="4" className="ion-text-end">
              <IonButton
                fill="clear"
                onClick={() => setShowMembersModal(true)}
                style={{ fontSize: '1rem', fontWeight: '500' }}
              >
                <IonIcon icon={people} slot="start" />
                {memberCount} Members
              </IonButton>
            </IonCol>
          </IonRow>

          {/* Timeframe Selector */}
          <IonRow className="ion-margin-bottom">
            <IonCol size="12" className="ion-text-center ion-text-md-end">
              {['Day', 'Week', 'Month', 'Qtr'].map((timeframe) => (
                <IonButton
                  key={timeframe}
                  color={selectedTimeframe === timeframe ? 'primary' : 'medium'}
                  onClick={() => setSelectedTimeframe(timeframe)}
                >
                  {timeframe}
                </IonButton>
              ))}
            </IonCol>
          </IonRow>

          {/* Team Goals Section */}
          <IonRow className="ion-margin-bottom">
            <IonCol size="6">
              <IonTitle size="small">Team Goals</IonTitle>
            </IonCol>
            <IonCol size="6" className="ion-text-end">
              <IonButton color="secondary" fill="clear" onClick={handleCreateGoalClick}>
                <IonIcon icon={add} slot="start" /> Add Goal
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            {teamGoals.map((goal) => (
              <IonCol size="12" sizeMd="4" key={goal._id} className="goal-col">
                <GoalCard
                  goal={goal}
                  onLike={() => handleLikeToggle(goal._id)}
                  onUnlike={() => handleLikeToggle(goal._id)}
                  onAddComment={(goalId, commentText) => handleAddComment(goalId, commentText)}
                  commentText={commentTexts[goal._id] || ''}
                  setCommentText={(text) =>
                    setCommentTexts((prevComments) => ({
                      ...prevComments,
                      [goal._id]: text,
                    }))
                  }
                  onAddReward={handleAddReward}
                  rewardAmount={rewardAmounts[goal._id] || ''}
                  setRewardAmount={(amount) =>
                    setRewardAmounts((prevAmounts) => ({
                      ...prevAmounts,
                      [goal._id]: amount,
                    }))
                  }
                  onStatusChange={(newStatus) => handleStatusChange(goal._id, newStatus)}
                />
              </IonCol>
            ))}
          </IonRow>

          {/* Individual Goals Section */}
          <IonRow className="ion-margin-top ion-margin-bottom">
            <IonCol size="6">
              <IonTitle size="small">Individual Goals</IonTitle>
            </IonCol>
            <IonCol size="6" className="ion-text-end">
              <IonButton color="secondary" fill="clear" onClick={handleCreateGoalClick}>
                <IonIcon icon={add} slot="start" /> Add Goal
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            {individualGoals.map((goal) => (
              <IonCol size="12" sizeMd="4" key={goal._id} className="goal-col">
                <GoalCard
                  goal={goal}
                  onLike={() => handleLikeToggle(goal._id)}
                  onUnlike={() => handleLikeToggle(goal._id)}
                  onAddComment={(goalId, commentText) => handleAddComment(goalId, commentText)}
                  commentText={commentTexts[goal._id] || ''}
                  setCommentText={(text) =>
                    setCommentTexts((prevComments) => ({
                      ...prevComments,
                      [goal._id]: text,
                    }))
                  }
                  onAddReward={handleAddReward}
                  rewardAmount={rewardAmounts[goal._id] || ''}
                  setRewardAmount={(amount) =>
                    setRewardAmounts((prevAmounts) => ({
                      ...prevAmounts,
                      [goal._id]: amount,
                    }))
                  }
                  onStatusChange={(newStatus) => handleStatusChange(goal._id, newStatus)}
                />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
      <AppFooter />

      {/* Members Modal */}
      <IonModal isOpen={showMembersModal} onDidDismiss={() => setShowMembersModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Team Members</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowMembersModal(false)}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {teamMembers && teamMembers.length > 0 ? (
              teamMembers.map((member, index) => (
                <IonItem key={index}>
                  <IonLabel>
                    {member.memberType === 'User'
                      ? `${member.memberId?.firstName} ${member.memberId?.lastName}`
                      : member.memberId?.name || member.memberId?.email}
                  </IonLabel>
                </IonItem>
              ))
            ) : (
              <IonItem>
                <IonLabel>No team members found.</IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonContent>
      </IonModal>

    </IonPage>
  );
};

export default TeamPage;
