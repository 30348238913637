import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { fetchUserTeams } from '../api/apiTeam';
import { fetchGoalsForTeam, createGoal } from '../api/apiGoal';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLabel,
  IonInput,
  IonTextarea,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonAlert,
  IonLoading,
} from '@ionic/react';

// Predefined options for dropdowns
const goalTypeOptions = [
  { key: 'Once', text: 'Once', value: 'Once' },
  { key: 'Daily', text: 'Daily', value: 'Daily' },
  { key: 'Weekly', text: 'Weekly', value: 'Weekly' },
  { key: 'Monthly', text: 'Monthly', value: 'Monthly' },
  { key: 'Quarterly', text: 'Quarterly', value: 'Quarterly' },
];

export default function CreateGoal({ mobile }) {
  const [parentGoals, setParentGoals] = useState([]);
  const [showParentGoalDropdown, setShowParentGoalDropdown] = useState(false);
  const [teams, setTeams] = useState([]);
  const [goalFormData, setGoalFormData] = useState({
    type: '',
    associatedTeam: '',
    title: '',
    parentGoal: '',
    targetCompletionDate: '',
    goalType: '',
    recurringFrequency: '',
    description: ''
  });
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const activeOrgId = localStorage.getItem('activeOrganization');

    fetchUserTeams(userId, activeOrgId)
      .then(data => {
        setTeams(data);
      })
      .catch(error => {
        console.error("Error fetching teams:", error);
        setFeedbackMessage({
          type: "error",
          content: "Error fetching teams: " + (error.message || error)
        });
      });
  }, []);

  const handleInputChange = (name, value) => {
    setGoalFormData(prev => {
      let updatedData = { ...prev, [name]: value };

      if (name === 'goalType') {
        if (value === 'Once') {
          updatedData.recurringFrequency = ''; // Use empty string for non-recurring goals
        } else {
          updatedData.recurringFrequency = value; // Set recurringFrequency equal to goalType
        }
      }

      return updatedData;
    });

    if (name === 'associatedTeam') {
      fetchGoalsForTeam(value)
        .then(data => {
          if (data.length > 0) {
            const parentGoalOptions = data.map(goal => ({
              key: goal._id,
              text: goal.title,
              value: goal._id,
            }));
            setParentGoals(parentGoalOptions);
            setShowParentGoalDropdown(true);
          } else {
            setShowParentGoalDropdown(false);
          }
        })
        .catch(error => console.error("Error fetching goals:", error));
    }
  };

  const validateForm = () => {
    const { type, title, targetCompletionDate, goalType } = goalFormData;

    if (!type || !title || !targetCompletionDate || !goalType) {
      return "All fields are required!";
    }

    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      setFeedbackMessage({
        type: "error",
        content: validationError
      });
      return;
    }

    setLoading(true);

    try {
      const requestData = {
        ...goalFormData,
        organizationId: localStorage.getItem('activeOrganization'),
        userId: localStorage.getItem('userId'),
        recurringFrequency: goalFormData.goalType === 'Once' ? '' : goalFormData.recurringFrequency // Use empty string for non-recurring goals
      };

      if (requestData.parentGoal === '') {
        requestData.parentGoal = null;
      }

      await createGoal(requestData);

      setFeedbackMessage({
        type: "success",
        content: "Goal created successfully!"
      });

      setGoalFormData({
        type: '',
        associatedTeam: '',
        title: '',
        parentGoal: '',
        targetCompletionDate: '',
        goalType: '',
        recurringFrequency: '',
        description: ''
      });
      history.push('/success');
    } catch (error) {
      setFeedbackMessage({
        type: "error",
        content: error.message || 'An error occurred while creating the goal.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Create A Goal</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        style={{
          '--background': 'linear-gradient(-45deg, #006d77, #83c5be)',
        }}
      >
        <div
          style={{
            width: "100%",
            minHeight: "100vh",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: mobile ? '1em' : '2em',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              maxWidth: '500px',
              width: '100%',
              borderRadius: '8px',
              marginBottom: '2em',
            }}
          >
            <IonList style={{ borderRadius: '10px', overflow: 'hidden' }}>
              <IonItem>
                <IonSelect
                  value={goalFormData.type}
                  onIonChange={(e) => handleInputChange('type', e.detail.value)}
                  labelPlacement="floating"
                  label="Type"
                  placeholder="Individual or Team"
                >
                  <IonSelectOption value="Individual">Individual</IonSelectOption>
                  <IonSelectOption value="Team">Team</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonSelect
                  value={goalFormData.associatedTeam}
                  onIonChange={(e) => handleInputChange('associatedTeam', e.detail.value)}
                  labelPlacement="floating"
                  label="Select Team"
                  placeholder="Select Team"
                  interface="alert"
                >
                  {teams.map(team => (
                    <IonSelectOption key={`team-${team.key}`} value={team.value}>{team.text}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonInput
                  name="title"
                  placeholder="Goal Title"
                  labelPlacement="floating"
                  label="Goal Title"
                  maxLength="100"
                  value={goalFormData.title}
                  onIonChange={(e) => handleInputChange('title', e.detail.value)}
                />
              </IonItem>
              {showParentGoalDropdown && (
                <IonItem>
                  <IonSelect
                    value={goalFormData.parentGoal}
                    onIonChange={(e) => handleInputChange('parentGoal', e.detail.value)}
                    labelPlacement="floating"
                    label="Parent Goal"
                    placeholder="Select Parent Goal"
                  >
                    {parentGoals.map(goal => (
                      <IonSelectOption key={`parentGoal-${goal.key}`} value={goal.value}>{goal.text}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              )}
              <IonItem>
                <IonInput
                  type="date"
                  name="targetCompletionDate"
                  placeholder="Target Date"
                  labelPlacement="floating"
                  label="Target Date"
                  value={goalFormData.targetCompletionDate}
                  onIonChange={(e) => handleInputChange('targetCompletionDate', e.detail.value)}
                />
              </IonItem>
              <IonItem>
                <IonSelect
                  value={goalFormData.goalType}
                  onIonChange={(e) => handleInputChange('goalType', e.detail.value)}
                  labelPlacement="floating"
                  label="Goal Type"
                  placeholder="Select Type"
                >
                  {goalTypeOptions.map(option => (
                    <IonSelectOption key={`goalType-${option.key}`} value={option.value}>{option.text}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonTextarea
                  name="description"
                  placeholder="Long Description"
                  labelPlacement="floating"
                  label="Long Description"
                  value={goalFormData.description}
                  onIonChange={(e) => handleInputChange('description', e.detail.value)}
                />
              </IonItem>
            </IonList>

            <div style={{ marginTop: '1em', marginBottom: '1em' }}>
              {feedbackMessage && (
                <IonAlert
                  isOpen={!!feedbackMessage}
                  onDidDismiss={() => setFeedbackMessage(null)}
                  message={feedbackMessage.content}
                  buttons={['OK']}
                />
              )}
              <IonButton expand="block" onClick={handleSubmit}>
                Submit
              </IonButton>
            </div>
          </div>
        </div>
        <IonLoading isOpen={loading} message={'Creating goal...'} />
      </IonContent>
    </IonPage>
  );
}
