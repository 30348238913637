import React, { useState, useEffect } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonMenu,
  IonContent,
  IonItem,
  IonList,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { menu, settingsOutline, logOutOutline } from 'ionicons/icons';
import { fetchTeams } from '../api/apiTeam';

const AppHeader = ({ children }) => {
  const [teams, setTeams] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchAndSetTeams = async () => {
      try {
        const teamData = await fetchTeams();
        setTeams(teamData);
      } catch (error) {
        console.error('There was an error fetching the teams', error.message || error);
      }
    };

    fetchAndSetTeams();
  }, []);

  const handleSettingsClick = () => {
    history.push('/settings');
  };

  const handleLogoutClick = () => {
    // Add your logout logic here
    history.push('/login');
  };

  return (
    <>
      <IonHeader>
        <IonToolbar
          style={{
            '--background': 'linear-gradient(0deg, #479d9d, #006d77)',
          }}
        >
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="light">Cochago</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={handleSettingsClick}>
              <IonIcon color="light" icon={settingsOutline} />
            </IonButton>
            <IonButton onClick={handleLogoutClick}>
              <IonIcon color="light" icon={logOutOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* IonMenu for the sidebar */}
      <IonMenu contentId="main-content" side="start">
        <IonContent>
          <IonList>
            <IonItem routerLink="/kanban">
              <IonLabel>Kanban</IonLabel>
            </IonItem>
            <IonItem routerLink="/teams">
              <IonLabel>Teams</IonLabel>
            </IonItem>
            <IonItem routerLink="/payments">
              <IonLabel>Payments</IonLabel>
            </IonItem>
            <IonItem routerLink="/password-reset">
              <IonLabel>Password Reset</IonLabel>
            </IonItem>
            {/* Teams Section */}
            <IonItem>
              <IonLabel>Teams</IonLabel>
            </IonItem>
            {teams.map((team) => (
              <IonItem key={team._id} routerLink={`/teams/${team._id}`}>
                <IonLabel>{team.name}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonMenu>

      {/* Main content */}
    </>
  );
};

export default AppHeader;
