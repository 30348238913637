// src/api/apiGoal.js

import { authenticatedFetch } from './api';

export const fetchGoalsForTeam = async (teamId) => {
  try {
    const goalsData = await authenticatedFetch(`/api/goals/${teamId}`);
    if (goalsData.length > 0) {
      const formattedGoals = goalsData.map(goal => ({
        key: goal._id,
        text: goal.title,
        value: goal._id,
      }));
      return formattedGoals;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error('There was an error fetching goals: ' + error.message);
  }
};

export const createGoal = async (requestData) => {
  try {
    const data = await authenticatedFetch('/api/createGoal', {
      method: 'POST',
      body: JSON.stringify(requestData),
    });
    return data;
  } catch (error) {
    throw new Error('There was an error creating the goal: ' + error.message);
  }
};

export const fetchGoalDetails = async (goalId) => {
  try {
    const response = await authenticatedFetch(`/api/goals/details/${goalId}`);
    return response;
  } catch (error) {
    throw new Error('Error fetching goal details: ' + error.message);
  }
};

export const addComment = async (goalId, text) => {
  try {
    console.log('API Request - Goal ID:', goalId); // Debug log
    console.log('API Request - Text:', text); // Debug log

    const response = await authenticatedFetch(`/api/goals/${goalId}/comments`, {
      method: 'POST',
      body: JSON.stringify({ text }),
    });

    return response;
  } catch (error) {
    throw new Error('Error adding comment: ' + error.message);
  }
};


export const addReward = async (goalId, amount) => {
  console.log(`Received request to add reward for goalId: ${goalId} with amount: ${amount}`);

  try {
    const response = await authenticatedFetch(`/api/goals/${goalId}/rewards`, {
      method: 'POST',
      body: JSON.stringify({ amount }),
    });
    return response;
  } catch (error) {
    throw new Error('Error adding reward: ' + error.message);
  }
};

export const changeStatus = async (goalId, newStatus) => {
  try {
    let endpoint = `/api/goals/${goalId}/status`;
    let body = { status: newStatus };
    let method = 'PATCH';

    if (newStatus === 'Done') {
      endpoint = `/api/goals/${goalId}/pending`;
      body = {};
      method = 'POST';
    }

    if (newStatus === 'Complete') {
      endpoint = `/api/goals/${goalId}/complete`;
      body = {};
      method = 'POST';
    }

    const response = await authenticatedFetch(endpoint, {
      method,
      body: JSON.stringify(body),
    });

    return response;
  } catch (error) {
    throw new Error('Error updating status: ' + error.message);
  }
};

export const fetchGoalsByTeamId = async (teamId) => {
  try {
    const response = await authenticatedFetch(`/api/goals/${teamId}`);
    return response;
  } catch (error) {
    throw new Error('There was an error fetching the goals: ' + error.message);
  }
};

export const likeGoal = async (goalId) => {
  try {
    const response = await authenticatedFetch(`/api/goals/${goalId}/like`, {
      method: 'POST',
    });

    // Directly return the updated goal object
    return response; // Assuming your middleware already parses JSON
  } catch (error) {
    throw new Error(`Error liking goal: ${error.message}`);
  }
};

export const unlikeGoal = async (goalId) => {
  try {
    const response = await authenticatedFetch(`/api/goals/${goalId}/unlike`, {
      method: 'POST',
    });

    // Directly return the updated goal object
    return response; // Assuming your middleware already parses JSON
  } catch (error) {
    throw new Error(`Error unliking goal: ${error.message}`);
  }
};



